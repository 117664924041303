import { render, staticRenderFns } from "./_.vue?vue&type=template&id=bdd51bec&scoped=true"
import script from "./_.vue?vue&type=script&lang=js"
export * from "./_.vue?vue&type=script&lang=js"
import style0 from "./_.vue?vue&type=style&index=0&id=bdd51bec&prod&lang=scss&scoped=true"
import style1 from "./_.vue?vue&type=style&index=1&id=bdd51bec&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd51bec",
  null
  
)

export default component.exports