export const useFormExtractor = (elements) => {
  return Array.from(elements)
    .filter(x => !!x.name)
    .map(x => {
      if (x.type === 'file') return { [x.name]: x.files[0] }
      if (x.type === 'checkbox') return { [x.name]: x.checked }
      else return { [x.name]: x.value }
    })
    .reduce((a, c) => Object.assign(a, c), {})
}
